<template>
  <v-app v-if="showFlag">
    <v-row justify="center" style="max-height: 80px !important;">
      <Menu style="margin-top: 15px"></Menu>
    </v-row>
    <v-main style="margin-top: 30px">
      <router-view/>
    </v-main>
    <v-footer style="margin-top: 50px;background:#323232;padding: 0">
      <v-row justify="center"
             style="padding:0;margin:0;background:url(https://www.akiba109.com:82/footer-bg.png) bottom center no-repeat;height: 160px;color:white;">
        <Foot></Foot>
      </v-row>
    </v-footer>
  </v-app>
  <v-app v-else>
    <v-container class="pa-0 ma-0" style="position: absolute;z-index: 99;height: 100%;width: 100%;"
                 v-if="menuFlag">
      <v-row class="ma-0 pa-0" style="height: 100%">
        <v-col cols="10" class="ma-0 pa-0" style="background:#232f3e;height: 850px;opacity: 1">
          <InsideMenu style="position:relative;z-index: 999;"></InsideMenu>
        </v-col>
        <v-col cols="2" class="pa-0 ma-0" style="background-color: black;opacity: 0.6;height:850px" @click="closeMenu" align="center">
          <v-icon style="font-size: 35px;margin-top: 15px" dark size="x-large" @click="closeMenu" >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-row justify="center" style="max-height: 80px !important;">
      <PhoneMenu></PhoneMenu>
    </v-row>
    <v-main style="margin-top: 30px">
      <router-view/>
    </v-main>
    <v-footer style="margin-top: 30px;background:#323232;padding: 0">
      <v-row justify="center"
             style="padding:0;margin:0;background:url(https://www.akiba109.com:82/footer-bg.png) bottom center no-repeat;color:white;">
        <PhoneFoot></PhoneFoot>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Foot from './components/Foot'
import PhoneFoot from "@/components/PhoneFoot.vue";
import Menu from './components/Menu';
import PhoneMenu from "@/components/PhoneMenu.vue";
import InsideMenu from "@/components/InsideMenu.vue";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    Foot,
    PhoneFoot,
    Menu,
    PhoneMenu,
    InsideMenu
  },
  computed: {
    ...mapGetters({
      menuFlag: 'menu/menuFlag',
    }),
    showFlag() {
      const url = window.location.href;
      // 提取第一层路径
      const pathname = new URL(url).pathname.split('/')[1];
      if (pathname == "phone") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    closeMenu() {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false})
    }
  },
  mounted() {
    const screenWidth = window.innerWidth;
    if (window.location.protocol !== 'https:') {
      window.location.href = `https://www.akiba109.com${window.location.pathname}`;
    }
    if (screenWidth < 750 && !window.location.pathname.includes('/phone')) {
      this.$router.push('/phone' + this.$route.path);
      location.reload()
    }
  }
};
</script>
<style>
.categoryMenuBtn {
  width: 200px;
  height: 40px;
  padding: 2px 0 0 30px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 5px;
  background: url('./assets/menupc.svg') 16px center no-repeat #777;
  display: table-cell;
  cursor: pointer;
  margin: 0;
  line-height: 1.5;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
}

.categoryMenuBtn:hover {
  color: #fff;
}


.calendar-pro__body table td .day-box.highlighter {
  background-color: pink !important;
  color: red !important;
}

.calendar-pro__body table td .day-box.highlighter .info-date {
  color: black !important;
}

.calendar-pro__body table td .day-box {
  height: 30px !important;
  padding: 0px 4px 0px 0px !important;
  font-size: 10px !important;
}

.calendar-pro__body table td {
  border: 0px !important;
}

.calendar-pro__body table td .info-date {
  text-align: end !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 16px !important;
}

.v-application {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', Osaka, sans-serif, arial, verdana, Helvetica, Verdana, "Droid Sans", Arial !important;
}

.v-carousel__controls {
  background: none !important;
}

.calendar-pro__body table td .day-box.other-month-day {
  opacity: 0 !important;
}

.calendar-pro__body table td .day-box.today {
  outline: 0px !important;
  z-index: 2;
}

.calendar-pro__body thead {
  display: none !important;
}

.calendar-pro__body table {
  width: 181px !important;
}

.calendar-pro__body table tbody {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.calendar-pro__body table td .day-box.disabled {
  color: black !important;
}

.calendar-pro__body table td .day-box.highlighter .info-date {
  color: red !important;
}

.showWeek calendar-pro {
  height: 200px !important;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* 隐藏水平滚动条 */
}

html {
  overflow-y: hidden;
}

</style>
